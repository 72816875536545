import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import _flattenDeep from 'lodash/flattenDeep';
import {canvasReducer as canvas,canvasMiddleware} from './reducers/canvas';
import {userAccessReducer as userAccess, userAccessMiddleware} from './reducers/userAccess';
import {eventReducer as  event, eventsMiddleware} from './reducers/event';
import {appReducer as  app, appMiddleware} from './reducers/app';

// redux reducers combined into a single option (makes easily accessible when debugging)
const reducer = combineReducers({
    canvas,
    userAccess,
    event,
    app
})

// using our custom middleware enables us to isolate the actions from the code that makes the http request
// using thunk would forces us to make the actions more complicated by sending the HTTP request and handling the response
const middleware = applyMiddleware.apply(null, _flattenDeep([
    canvasMiddleware,
    userAccessMiddleware,
    eventsMiddleware,
    appMiddleware
]))

// enable redux debugging
const composeEnhancers = (typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose;

const store = createStore(reducer, composeEnhancers(middleware));
export default store;