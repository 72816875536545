import moment from 'moment-timezone'
// You need to force locales to be included
import 'moment/locale/en-gb'

const locale = 'en-gb'
const timezone = 'Europe/London'

export const parseDate = (dateStr, localeUpdate = locale, timeZoneUpdate = timezone) => {
    const date = moment.tz(dateStr, [moment.ISO_8601, 'll', 'LL', 'l', 'L'], localeUpdate, timeZoneUpdate)
    return date
}

/**
 * A moment for now.
 * @return {*|moment.Moment}
 */
export const now = () => {
    return moment()
}