import { combineReducers } from 'redux';
import {
  SET_DATE_FROM,
  SET_DATE_TO,
  SET_TYPES,
  CALENDAR_EVENTS, ASSIGNMENTS, DISCUSSIONS, TODOS, APP_GROUPS
} from '../actions/event'
import { createLoadingReducer } from '../utils/genericReducer'
import { createLoadingMiddleware } from '../utils/genericMiddleware'
import {settings} from '../utils/constants'

const initialState = {
	dateTo: "",
	dateFrom: "",
    eventTypes: ""
}

export const eventTypesReducer = (state = initialState, action) => {

    const newState = {...state}

	switch(action.type) {
		case SET_DATE_TO: {
			newState.dateTo = action.result
			return newState;
		}
		case SET_DATE_FROM: {
			newState.dateFrom = action.result
			return newState;
		}
		case SET_TYPES: {
			newState.eventTypes = action.result
			return newState;
		}
		default : {
			return state;
		}
	}
}

export const eventReducer = combineReducers({
	events: createLoadingReducer(CALENDAR_EVENTS),
	assignments: createLoadingReducer(ASSIGNMENTS),
	discussions: createLoadingReducer(DISCUSSIONS),
	todos: createLoadingReducer(TODOS),
	appGroups: createLoadingReducer(APP_GROUPS),
  eventTypes: eventTypesReducer
});


const getEventsUrl = ({token, courseId, startDate, endDate}) => {

    let url = `${settings.proxyServer}/api/v1/calendar_events?context_codes%5B%5D=course_${courseId}&type=event`;
   
    if(!endDate && !startDate) {
        url += `&all_events=true`
    } else {   
        url += `&start_date=${startDate}&end_date=${endDate}`
    }

    return {
        url,
        method: 'GET',
        token: token
    }
}

const getTodosUrl = ({token, courseId, startDate, endDate}) => {
    const url = `${settings.proxyServer}/api/v1/planner_notes?context_codes%5B%5D=course_${courseId}&start_date=${startDate}&end_date=${endDate}`;
    return {
        url,
        method: 'GET',
        token: token
    }
}

const getAssignmentsUrl = ({token, courseId, startDate, endDate}) => {

    let url = `${settings.proxyServer}/api/v1/calendar_events?context_codes%5B%5D=course_${courseId}&type=assignment&start_date=${startDate}&end_date=${endDate}`
    return {
        url,
        method: 'GET',
        token: token
    }
}
const getDiscussionUrl = ({token, courseId}) => {
    const url = `${settings.proxyServer}/api/v1/courses/${courseId}/discussion_topics`;
    return {
        url,
        method: 'GET',
        token: token
    }
}

const getManageableAppGroupsUrl = ({token, courseId}) => {
  return getAppGroupsUrl(token, courseId, 'manageable')
}

const getReservableAppGroupsUrl = ({token, courseId}) => {
  return getAppGroupsUrl(token, courseId, 'reservable')
}

const getAppGroupsUrl = (token, courseId, scope) => {
  const scopeParam = `scope=${scope}`
  const includeParams = 'include%5B%5D=appointments&include%5B%5D=child_events'
  const contextCodesParam = `context_codes%5B%5D=course_${courseId}`
  const url = `${settings.proxyServer}/api/v1/appointment_groups?${includeParams}&${scopeParam}&${contextCodesParam}`
  return {
    url,
    method: 'GET',
    token: token
  }
}

// set up for middleware operations
// helpful for making async and api calls
export const eventsMiddleware = [
  createLoadingMiddleware(CALENDAR_EVENTS, getEventsUrl),
  createLoadingMiddleware(TODOS, getTodosUrl),
  createLoadingMiddleware(ASSIGNMENTS, getAssignmentsUrl),
  createLoadingMiddleware(DISCUSSIONS, getDiscussionUrl),
  createLoadingMiddleware(APP_GROUPS, getReservableAppGroupsUrl),
  createLoadingMiddleware(APP_GROUPS, getManageableAppGroupsUrl)
];