const PREFIX = 'app';
export const SHOW_EXPORT = PREFIX + ':show:export'

/**
 * Action for showing the PDF export page.
 * This is in the redux store so that when a user grants access we can re-try the data load.
 */
export const setShowExport = (value = true) => {
    return{
        type: SHOW_EXPORT,
        result: value
    }
}