import {loadJSON} from "./loadJSON";
import {promptUserLogin} from "../actions/userAccess";
import {ERORS} from './constants'
import { ACTION_LOADED_SUFFIX } from './genericReducer'

/**
 * This middleware watches for actions that need to load date from a remote server and when it sees one it makes
 * a fetch call using loadJson.
 * 
 * @param loadingAction The type of the action to load the data.
 * @param loadedAction The type of the action to dispatch when the data has been loaded.
 * @param prepareCall Function used to create the config for loadJson from the state/action.
 * @param echoOriginalAction If true, include the original action on the dispatched action on load
 * @return {function(*=): function(*): function(*=): (Promise<void>)}
 */
export function createLoadingMiddleware(myaction, prepareCall, echoOriginalAction = false) {

    const loadingAction = myaction
    const loadedAction = myaction + ACTION_LOADED_SUFFIX

    const genericMiddleware = store => next => action => {
        switch(action.type) {
            case loadingAction: {
                // Allow the action to continue down to the reducer
                next(action);

                const config = prepareCall(action, store);
                const originalAction = echoOriginalAction ? action : undefined;

                return loadJSON(config).then(result => {
                    const resultAction = {
                        type: loadedAction,
                        result
                    };
                    if(echoOriginalAction) {
                        resultAction.originalAction = action;
                    }
                    store.dispatch(resultAction);
                }, setupErrorHandler(store, loadedAction, undefined, originalAction));
            }
            default:
                return next(action);
        }
    };

    return genericMiddleware;
}

function setupErrorHandler(store, actionName, actionId, originalAction) {
    return error => {
        store.dispatch({
            actionId,
            originalAction,
            type: actionName,
            error
        });

        // should only ask users to login when they need to be granted access
        if (error.name === ERORS.LOGIN) {
            store.dispatch(promptUserLogin())
        }
    }
}