import { combineReducers } from 'redux'
import { SHOW_EXPORT } from '../actions/app'
import { createFlagReducer } from '../utils/genericReducer'
import { eventTypes as eventConst } from '../utils/constants'
import { getAppGroups, getAssignments, getDiscussions, getEvents, getTodos } from '../actions/event'

export const appReducer = combineReducers({
	showExport: createFlagReducer(SHOW_EXPORT),
})

export const dispatchEventLoad = (store) => {
	const state = store.getState()
	const { eventTypes, dateFrom, dateTo } = state.event.eventTypes
	const { canvasCourse: { courseId } } = state.canvas
	const { jwtToken } = state.userAccess
	if (eventTypes.includes(eventConst.EVENTS)) {
		store.dispatch(getEvents(courseId, jwtToken, dateFrom, dateTo))
	}
	if (eventTypes.includes(eventConst.ASSIGNMENTS) || eventTypes.includes(eventConst.QUIZ)) {
		store.dispatch(getAssignments(courseId, jwtToken, dateFrom, dateTo))
	}
	if (eventTypes.includes(eventConst.DISCUSSION)) {
		store.dispatch(getDiscussions(courseId, jwtToken))
	}
	if (eventTypes.includes(eventConst.TODOS)) {
		store.dispatch(getTodos(courseId, jwtToken, dateFrom, dateTo))
	}
	if (eventTypes.includes(eventConst.APP_GROUP)) {
		store.dispatch(getAppGroups(courseId, jwtToken, dateFrom, dateTo))
	}
}

// set up for middleware operations helpful for making async and api calls
export const appMiddleware = [
	store => next => action => {
		switch(action.type) {
			// When we're asked to show the export we load all the data
			case SHOW_EXPORT: {
				// Allow the action to continue down to the reducer
				const defaultNext = next(action)
				dispatchEventLoad(store)
				return defaultNext
			}
			default:
				return next(action);
		}
	}
]
