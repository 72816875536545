import CustomError from '../service/CustomerErrorHandler'
import parseLinkHeader from 'parse-link-header'
import {ERORS} from './constants'

export const loadJSON = async (configOrURL) => {

    let config;
    if(configOrURL.url) {
        config = configOrURL;
    } else {
        config = {
            url: configOrURL
        };
    }
    if(!config.method) {
        config.method = 'GET';
    }

    let myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${config.token}`);
    
    let formBody = [];
    if(config.method === 'POST') {
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

      for (let property in config.body) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(config.body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
    } else {
      myHeaders.append('Content-Type', 'application/json');
      formBody = null;
    }

    const configuration = {
        method: config.method,
        headers: myHeaders,
        cache: 'default',
        body: formBody
    };

    const loadedResponse = []
    const resultWithHeader = {}
    let nextUrl = config.url

    do {

      const response = await fetch(nextUrl, configuration)
      .then(handleErrors)
      .then(result => {
        
        if (!result.ok) {
          throw new Error('Problem fetching API (status: ' + result.status + ')')
        }
        return result
      })
        const json = await response.json()
        const links = parseLinkHeader(response.headers.get('Link'))
        nextUrl = null;
        if(links && links.next) {
          nextUrl = links.next.url
        }

        
        if(config.method !== 'GET') {
          loadedResponse.push(json)
        } else {
          loadedResponse.push(...json)
        }

        // set response to load json
        resultWithHeader["data"] = loadedResponse;
        resultWithHeader["id"] = config.id;
        
        if(config.noLoop) {
          resultWithHeader["links"] = links;
          break;
        }

  
    } while(nextUrl)

    return resultWithHeader;
}


/**
 * Handle generic errors when performing a fetch.
 *
 * @param response The response to the fetch.
 * @returns {{ok}|*|Promise<T>}
 */
export const handleErrors = (response) => {
  if (!response.ok) {
    if (response.status === 403) {
      // This will happen the first time someone uses the tool.
      throw new CustomError(ERORS.LOGIN, 'Proxy doesn\'t have token for user', response.status)
    } else if (response.status === 401) {
      const authHeader = response.headers.get('WWW-Authenticate')
      if (authHeader) {
        if (!authHeader.includes('proxy')) {
          // This will typically happen when someone has deleted their token
          throw new CustomError(ERORS.LOGIN,'Your token isn\'t valid any more', response.status)
        }
        // TODO We should have better parsing of the header
        if (authHeader.includes('invalid_token')) {
          throw new Error('Your session has expired, please try relaunching the tool')
        }
      }
      // If there's no auth header look in the JSON
      return response.json().then(error => {
        if (error.status === 'unauthorized') {
          // Attempting an operation for something they don't have access to.
          throw new CustomError(ERORS.UNAUTHORIZED, error.status)
        } else {
          // This is probably because you need to renew you're token because the scopes have changed.
          throw new CustomError(ERORS.LOGIN, 'Token isn\'t valid for this operation.')
        }
      })
    } else if (response.status === 400) {
        response.text().then(err => console.error(err))
    }
  }
  return response
}