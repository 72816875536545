// environments
export const LOCAL = 'https://localhost:3000'
export const DEV = 'https://master.calendar-pdf-export.pages.dev'
export const PROD = 'https://calendar-pdf-export.canvas.ox.ac.uk'

export const urlSettings = {
    [LOCAL]: {
      ltiServer: process.env.REACT_APP_LTI_URL,
      proxyServer: process.env.REACT_APP_PROXY_URL
    },
    [DEV]: {
      ltiServer: 'https://lti-dev.canvas.ox.ac.uk',
      proxyServer: 'https://proxy-dev.canvas.ox.ac.uk',
      sentryDsn: 'https://fc58db240bcb40558a38cf28ece8a30d@o419652.ingest.sentry.io/5918923',
      sentryEnv: 'dev'
    },
    [PROD]: {
      ltiServer: 'https://lti.canvas.ox.ac.uk',
      proxyServer: 'https://proxy.canvas.ox.ac.uk',
      sentryDsn: 'https://c82bb02a8467408e9483af0f1967f537@o419652.ingest.sentry.io/5918923',
      sentryEnv: 'prod'
    }
}

/**
 * The settings for this deployment of the application (based on the origin)
 */
export const settings = urlSettings[window.origin]

export const eventTypes = {
  EVENTS: "Event",
  ASSIGNMENTS: "Assignment",
  QUIZ: "Quiz",
  DISCUSSION: "Discussion",
  TODOS: "Non-graded items with a to-do date",
  APP_GROUP: "Appointment groups",
  APPOINTMENT: "Appointment"
}

export const ERORS = {
  LOGIN: 'LoginError',
  UNAUTHORIZED: 'Unauthorized Error'
}
