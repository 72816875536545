import React from 'react'
import { IconArrowOpenEndSolid, IconArrowOpenStartSolid } from '@instructure/ui-icons'
import { setEventEndDate, setEventStartDate } from '../../actions/event'
import { AccessibleContent } from '@instructure/ui-a11y-content'
import { DateInput } from '@instructure/ui-date-input'
import { IconButton } from '@instructure/ui-buttons'
import { Calendar } from '@instructure/ui-calendar'
import { FormFieldGroup } from '@instructure/ui-form-field'
import { now, parseDate } from '../../utils/commonFunctions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TO_DATE = 'toDate'
const FROM_DATE = 'fromDate'

export class EventDate extends React.Component {

  static propTypes = {
    setEventEndDate: PropTypes.func.isRequired,
    setEventStartDate: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    hasReversedDates: PropTypes.bool.isRequired,
    resolveError: PropTypes.func.isRequired,
    locale: PropTypes.string,
    timezone: PropTypes.string
  }

  static defaultProps = {
    locale: 'en-gb',
    timezone: 'Europe/London'
  }

  state = {
    // This is the value in the box, it can be edited by users.
    fromDateValue: '',
    // This is the value in the box it can be edited by users.
    toDateValue: '',
    isShowingCalendarFrom: false,
    isShowingCalendarTo: false,
    // We can use a moment date for this.
    todayDate: now(),
    // The value selected, this can be null
    selectedDateFrom: null,
    // The value select, this can be null
    selectedDateTo: null,
    // This is the value rendered as the user navigates the calendar
    renderedDateFrom: now().toISOString(),
    // This is the value rendered as the user navigates the calendar
    renderedDateTo: now().toISOString(),
    messagesFrom: [],
    messagesTo: [],
    messagesDates: []
  }


  componentDidUpdate(prevProps, prevState) {
    const {setEventEndDate, setEventStartDate, hasError, hasReversedDates, resolveError } = this.props;
    const {selectedDateFrom, selectedDateTo} = this.state
    if(prevState.selectedDateFrom !== selectedDateFrom) {
      // We want to use the start of the day so that it includes all events from that day
      const renderFromDate = selectedDateFrom && this.parse(selectedDateFrom).startOf('day').toISOString()
      setEventStartDate(renderFromDate)
    }
    if(prevState.selectedDateTo !== selectedDateTo) {
      // We want to use the end of the day so that it includes all events from that day
      const renderToDate = selectedDateTo && this.parse(selectedDateTo).endOf('day').toISOString()
      setEventEndDate(renderToDate)
    }

    if(hasError && (hasError !== prevProps.hasError)) {
      if(!selectedDateTo) {
         this.setState({messagesTo: [{ type: 'error', text: 'Required' }]})
       }
       if(!selectedDateFrom) {
         this.setState({messagesFrom: [{ type: 'error', text: 'Required' }]})
       }
       resolveError()
    }
    if (hasReversedDates && hasReversedDates !== prevProps.hasReversedDates) {
      this.setState({messagesDates: hasReversedDates?[{type: 'error', text: 'From date is after To date'}]:[]})
      resolveError()
    }
  }

  parse = (dateString) => {
    const {locale, timezone} = this.props
    return parseDate(dateString, locale, timezone)
  }

  generateMonth = (dateType) => {
    let renderedDate = this.state.renderedDateFrom
    if(dateType === TO_DATE) {
      renderedDate = this.state.renderedDateTo
    }
    const date = this.parse(renderedDate)
      .startOf('month')
      .startOf('week')

    return Array.apply(null, Array(Calendar.DAY_COUNT)).map(() => {
      const currentDate = date.clone()
      date.add(1, 'days')
      return currentDate
    })
  }

  formatDate = (dateInput) => {
    const date = this.parse(dateInput)
    return date.format('LL')
  }

  handleChange = (event, { value }) => {
    const {target: {name}} = event
    const newDateStr = this.parse(value).toISOString()
    
    if(name === TO_DATE) {
      this.setState(({ renderedDateTo }) => ({
        toDateValue: value,
        selectedDateTo: newDateStr,
        renderedDateTo: newDateStr || renderedDateTo,
        messagesTo: [],
        messagesDates: []
      }))
    } else {
      this.setState(({ renderedDateFrom }) => ({
        fromDateValue: value,
        selectedDateFrom: newDateStr,
        renderedDateFrom: newDateStr || renderedDateFrom,
        messagesFrom: [],
        messagesDates: []
      }))
    }
  }

  handleShowCalendar = (dateType) => {
    let newState = {
      isShowingCalendarFrom: true
    };
    if(dateType === TO_DATE) {
      newState = {
        isShowingCalendarTo: true
      };
    }
    this.setState(newState)
  }

  handleHideCalendar = (dateType) => {

    if(dateType === TO_DATE) {

      this.setState(({ selectedDateTo, toDateValue }) => ({
        isShowingCalendarTo: false,
        toDateValue: selectedDateTo ? this.formatDate(selectedDateTo) : toDateValue
      }))
    } else {
      this.setState(({ selectedDateFrom, fromDateValue }) => ({
        isShowingCalendarFrom: false,
        fromDateValue: selectedDateFrom ? this.formatDate(selectedDateFrom) : fromDateValue
      }))
    }
  }

  handleValidateDate = (event) => {
    this.setState(({ selectedDateFrom, selectedDateTo, toDateValue, fromDateValue }) => {
      const newState = {}
      // We don't have a selectedDate but we have a fromDateValue or toDateValue. That means that the fromDateValue or toDateValue
      // could not be parsed and so the date is invalid
      if (!selectedDateFrom && fromDateValue) {
        newState.messagesFrom = [{ type: 'error', text: 'This date is invalid' }]
      }
      if (!selectedDateTo && toDateValue) {
        newState.messagesTo = [{ type: 'error', text: 'This date is invalid' }]
      }
      return newState
    })
  }

  handleDayClick = (date , dateType) => {

    if(dateType === TO_DATE) {
      this.setState({
        selectedDateTo: date,
        renderedDateTo: date,
        messagesTo: []
      })
    } else {
      this.setState({
        selectedDateFrom: date,
        renderedDateFrom: date,
        messagesFrom: []
      })
    }
  }

  handleSelectNextDay = (dateType) => {
    this.modifySelectedDate('day', 1, dateType)
  }

  handleSelectPrevDay = (dateType) => {
    this.modifySelectedDate('day', -1, dateType)
  }

  handleRenderNextMonth = (dateType) => {
    this.modifyRenderedDate('month', 1, dateType)
  }

  handleRenderPrevMonth = (dateType) => {
    this.modifyRenderedDate('month', -1, dateType)
  }

  modifyRenderedDate = (type, step, dateType) => {

    if(dateType === TO_DATE) {
      this.setState(({ renderedDateTo }) => {
        return { renderedDateTo: this.modifyDate(renderedDateTo, type, step) }
      })
    } else {
      this.setState(({ renderedDateFrom }) => {
        return { renderedDateFrom: this.modifyDate(renderedDateFrom, type, step) }
      })
    }
  }

  modifySelectedDate = (type, step, dateType) => {
    this.setState(({ selectedDateFrom, selectedDateTo, renderedDateFrom, renderedDateTo }) => {
      // We are either going to increase or decrease our selectedDate by 1 day.
      // If we do not have a selectedDate yet, we'll just select the first day of
      // the currently rendered month instead.

      let newState
      if (dateType === TO_DATE) {
        let newDate = selectedDateTo ? this.modifyDate(selectedDateTo, type, step) : this.parse(renderedDateTo).startOf('month').toISOString()

        newState = {
          selectedDateTo: newDate,
          renderedDateTo: newDate,
          toDateValue: this.formatDate(newDate),
          messagesTo: []
        }
      } else {
        let newDate = selectedDateFrom ? this.modifyDate(selectedDateFrom, type, step) : this.parse(renderedDateFrom).startOf('month').toISOString()

        newState = {
          selectedDateFrom: newDate,
          renderedDateFrom: newDate,
          fromDateValue: this.formatDate(newDate),
          messagesFrom: []
        }
      }

      return newState;
    })
  }

  modifyDate = (dateStr, type, step) => {
    const date = this.parse(dateStr)
    date.add(step, type)
    return date.toISOString()
  }

  renderWeekdayLabels = (dateType) => {

    let renderedDate = this.state.renderedDateFrom
    if(dateType === TO_DATE) {
      renderedDate = this.state.renderedDateTo
    }
    const date = this.parse(renderedDate).startOf('week')

    return Array.apply(null, Array(7)).map(() => {
      const currentDate = date.clone()
      date.add(1, 'day')

      return (
        <AccessibleContent alt={currentDate.format('dddd')}>
          {currentDate.format('dd')}
        </AccessibleContent>
      )
    })
  }

  renderDays (dateType) {
    const {
      selectedDateFrom,
      selectedDateTo,
      renderedDateFrom,
      renderedDateTo,
      todayDate,
    } = this.state

    let selectedDate =  selectedDateFrom
    let renderedDate =  renderedDateFrom
    if (dateType === TO_DATE) {
      selectedDate = selectedDateTo
      renderedDate = renderedDateTo
    }
    
    
    return this.generateMonth(dateType).map((date) => {
      const dateStr = date.toISOString()

      // flag to disalbe days to be selected from calendar based on if toDate/fromDate has been selected
      let disableFlag = false
      if((dateType === TO_DATE) && (selectedDateFrom && new Date(selectedDateFrom).getTime() > new Date(dateStr).getTime())) {
        disableFlag = true
      }
      if((dateType === FROM_DATE) && (selectedDateTo && new Date(selectedDateTo).getTime() < new Date(dateStr).getTime())) {
        disableFlag = true
      }

      return (
        <DateInput.Day
          key={dateStr}
          date={dateStr}
          interaction={disableFlag ? 'disabled' : 'enabled'}
          isSelected={date.isSame(selectedDate, 'day')}
          isToday={date.isSame(todayDate, 'day')}
          isOutsideMonth={!date.isSame(renderedDate, 'month')}
          label={date.format('LL')}
          onClick={() => this.handleDayClick(dateStr, dateType)}
        >
          {date.format('D')}
        </DateInput.Day>
      )
    })
  }


  render() {
    const {
      fromDateValue,
      toDateValue,
      isShowingCalendarFrom,
      isShowingCalendarTo,
      renderedDateFrom,
      renderedDateTo,
      messagesFrom,
      messagesTo,
      messagesDates
    } = this.state

    const dateFrom = this.parse(renderedDateFrom)
    const dateTo = this.parse(renderedDateTo)

    const buttonProps = (type = 'prev') => ({
      size: 'small',
      withBackground: false,
      withBorder: false,
      renderIcon: type === 'prev'
        ? <IconArrowOpenStartSolid color="primary" />
        : <IconArrowOpenEndSolid color="primary" />,
      screenReaderLabel: type === 'prev' ? 'Previous month' : 'Next month'
    })

    return (
      <FormFieldGroup messages={messagesDates} layout='columns' description='Date Range for Events: *' vAlign='top'>
        <DateInput
          renderLabel='From date: *'
          assistiveText='Type a date or use arrow keys to navigate date picker.'
          value={fromDateValue}
          onChange={this.handleChange}
          aria-controls='startdate'
          width='18rem'
          isInline
          isRequired={true}
          name={FROM_DATE}
          messages={messagesFrom}
          isShowingCalendar={isShowingCalendarFrom}
          onRequestValidateDate={this.handleValidateDate}
          onRequestShowCalendar={() => this.handleShowCalendar(FROM_DATE)}
          onRequestHideCalendar={() => this.handleHideCalendar(FROM_DATE)}
          onRequestSelectNextDay={this.handleSelectNextDay}
          onRequestSelectPrevDay={this.handleSelectPrevDay}
          onRequestRenderNextMonth={this.handleRenderNextMonth}
          onRequestRenderPrevMonth={this.handleRenderPrevMonth}
          renderNavigationLabel={
            <span id='startdate'>
                      <div>{dateFrom.format('MMMM')}</div>
                      <div>{dateFrom.format('YYYY')}</div>
                    </span>
          }
          renderPrevMonthButton={<IconButton {...buttonProps('prev')} />}
          renderNextMonthButton={<IconButton {...buttonProps('next')} />}
          renderWeekdayLabels={this.renderWeekdayLabels()}
        >
          {this.renderDays(FROM_DATE)}
        </DateInput>
        <DateInput
          renderLabel='To date: *'
          assistiveText='Type a date or use arrow keys to navigate date picker.'
          value={toDateValue}
          name={TO_DATE}
          onChange={this.handleChange}
          aria-controls='enddate'
          width='18rem'
          isInline
          messages={messagesTo}
          isRequired={true}
          isShowingCalendar={isShowingCalendarTo}
          onRequestValidateDate={() => this.handleValidateDate(TO_DATE)}
          onRequestShowCalendar={() => this.handleShowCalendar(TO_DATE)}
          onRequestHideCalendar={() => this.handleHideCalendar(TO_DATE)}
          onRequestSelectNextDay={() => this.handleSelectNextDay(TO_DATE)}
          onRequestSelectPrevDay={() => this.handleSelectPrevDay(TO_DATE)}
          onRequestRenderNextMonth={() => this.handleRenderNextMonth(TO_DATE)}
          onRequestRenderPrevMonth={() => this.handleRenderPrevMonth(TO_DATE)}
          renderNavigationLabel={
            <span id='enddate'>
                    <div>{dateTo.format('MMMM')}</div>
                    <div>{dateTo.format('YYYY')}</div>
                  </span>
          }
          renderPrevMonthButton={<IconButton {...buttonProps('prev')} />}
          renderNextMonthButton={<IconButton {...buttonProps('next')} />}
          renderWeekdayLabels={this.renderWeekdayLabels(TO_DATE)}
        >
          {this.renderDays(TO_DATE)}
        </DateInput>
      </FormFieldGroup>
    )
  }
}

export default connect(null, {setEventEndDate, setEventStartDate})(EventDate)