const PREFIX = 'canvas';
export const SET_COURSE = PREFIX + ':set:course';
export const SET_ACCOUNT = PREFIX + ':set:account';

export function setCourse(value) {
    return {
        type: SET_COURSE,
        result: value 
    }
}

export function setAccount(value) {
    return {
        type: SET_ACCOUNT,
        result: value 
    }
}