import React from 'react'
import { View } from '@instructure/ui-view'
import EventDate from '../../components/dates/EventDate'
import EventTypes from '../../components/types/EventTypes'
import PdfExport from '../../components/pdfExport/PdfExport'
import { setShowExport } from '../../actions/app'
import { Heading } from '@instructure/ui-heading'
import Error from '../../components/error/Error'
import { Text } from '@instructure/ui-text'
import { Button } from '@instructure/ui-buttons'
import { connect } from 'react-redux'
import { Flex } from '@instructure/ui-flex'
import { Alert } from '@instructure/ui-alerts'
import { Spinner } from '@instructure/ui-spinner'

class Home extends React.Component {
  state = {
    error: null,
    hasNoDates: false,
    hasReversedDates: false,
    hasNoEventTypes: false,
    triggerPDF: false
  }

  token = null

  componentDidUpdate(prevState, prevProps) {

      if(this.state.showExport !== prevState.showExport) {
        if(this.state.triggerPDF && (this.props.populatedEvents !== prevProps.populatedEvents) && this.props.populatedEvents) {
          this.printDocument();
          this.setState({triggerPDF: false})
        }
    }
  }

  handleExport = () => {
    const { event: {dateTo, dateFrom, eventTypes} } = this.props;

    let valid = true
    this.setState({
      hasNoDates: false,
      hasReversedDates: false,
      hasNoEventTypes: false
    })
    if(!dateFrom || !dateTo) {
      this.setState({hasNoDates: true})
      valid = false
    } else {
      if (new Date(dateFrom).getTime() > new Date(dateTo).getTime()) {
        this.setState({hasReversedDates: true})
        valid = false
      }
    }

    if(!eventTypes.length) {
      this.setState({hasNoEventTypes: true})
      valid = false
    }

    if(valid) {
      this.props.setShowExport(true)
    }
  }

  render() {
    const {error, hasNoDates, hasReversedDates, hasNoEventTypes} = this.state;
    const {loading, locale, timezone, courseName, loaded, returnUrl, hasError, showExport, setShowExport} = this.props;

    if(showExport && loaded && !hasError) {
      return <PdfExport handleClose={() => setShowExport(false)} timezone={timezone} />
    }

    return (
            <View padding="small" as="div">
              <Error message={error}>
                <Heading level="h1" margin="medium none" > Export course calendar to PDF </Heading>
                {hasError && <Alert variant="error" timeout={10000}> Error generating events, please refresh the page and try again.</Alert>}
                <Text size="large"> Course: {courseName} </Text> <br/><br/> <br/>

                {/* The error handing here (with resolveError) is messy and should be refactored in the future. */}
                <EventDate locale={locale} timezone={timezone} hasError={hasNoDates} hasReversedDates={hasReversedDates} resolveError={() => this.setState({hasNoDates: false, hasReversedDates: false})}/>

                <EventTypes hasError={hasNoEventTypes} resolveError={()=>this.setState({hasNoEventTypes: false})}/>

                <View borderWidth='small none none none' borderColor='primary' as='div'>
                  <Flex justifyItems='end' wrap='wrap'>
                    <Flex.Item>
                      {loading && <Spinner size='small' renderTitle='Loading events'/> }
                    </Flex.Item>
                    <Flex.Item margin='small xx-small'>
                      <Button color='secondary' href={returnUrl} interaction={returnUrl ? 'enabled' : 'disabled'}>
                        Cancel
                      </Button>
                    </Flex.Item>
                    <Flex.Item margin='small xx-small'>
                      <Button color='primary' onClick={() => this.handleExport()} interaction={loading?'disabled':'enabled'}>
                        Generate Events
                      </Button>
                    </Flex.Item>
                  </Flex>
                </View>
              </Error>
            </View>
      )
  }
}

const mapStateToProps = (state) => {
  const {canvas: {canvasCourse} , event: {eventTypes, events, assignments, discussions, todos, appGroups }, app: {showExport}} = state;
    
  const loading = events.loading || assignments.loading || discussions.loading || todos.loading || appGroups.loading
  const isLoaded = events => !events.requested || !events.loading
  const loaded = isLoaded(events) && isLoaded(assignments) && isLoaded(discussions) && isLoaded(todos) && isLoaded(appGroups)

  let hasError = false;
    if((events && events.error)|| (assignments && assignments.error)|| (discussions && discussions.error)|| (todos && todos.error)|| (appGroups && appGroups.error)) {
      hasError = true;
    }
    return {
        courseName: canvasCourse && canvasCourse.courseName,
        event: eventTypes,
        hasError,
        loading,
        loaded,
        showExport,
    }
}

export default connect(mapStateToProps, {setShowExport})(Home)