import { combineReducers } from 'redux';
import { SET_COURSE, SET_ACCOUNT } from '../actions/canvas';

const initialState = {
	courseId: "",
	courseName: ""
}

const initialAccountState = {
	accountId: ""
}

const canvasCourseReducer = (state = initialState, action) => {
	switch(action.type) {
		case SET_COURSE: {
			const newState = {...state}
			newState.courseName = action.result.name
			newState.courseId = action.result.id
			return newState;
		}
		default : {
			return state;
		}
	}
}

const canvasAccountReducer = (state = initialAccountState, action) => {
	switch(action.type) {
		case SET_ACCOUNT: {
			const newState = {...state}
			newState.accountId = action.result.id
			return newState;
		}
		default : {
			return state;
		}
	}
}


export const canvasReducer = combineReducers({
	canvasCourse: canvasCourseReducer,
	canvasAccount: canvasAccountReducer
});

export const canvasMiddleware = [
];
