import React from 'react'
import { View } from '@instructure/ui-view'
import { Checkbox } from '@instructure/ui-checkbox'
import { IconCalendarMonthLine, IconAssignmentLine, IconQuizLine, IconDiscussionLine, IconDocumentLine, IconCalendarAddLine } from '@instructure/ui-icons'
import {setEventType} from '../../actions/event'
import {eventTypes} from '../../utils/constants'
import {connect} from 'react-redux'
import { FormFieldGroup } from '@instructure/ui-form-field'

const getStringsFromId = (value) => {
  let toString = ""
  if(value.includes("eventId")) {
    toString = toString.concat(eventTypes.EVENTS + ", ")
  }
  if(value.includes("assignmentId")) {
    toString = toString.concat(eventTypes.ASSIGNMENTS + ", ")
  }
  if(value.includes("quizId")) {
    toString = toString.concat(eventTypes.QUIZ + ", ")
  }
  if(value.includes("discussionId")) {
    toString = toString.concat(eventTypes.DISCUSSION + ", ")
  }
  if(value.includes("nonGradeId")) {
    toString = toString.concat(eventTypes.TODOS + ", ")
  }
  if(value.includes("appointmentId")) {
    toString = toString.concat(eventTypes.APP_GROUP + ", ")
  }
  return toString.slice(0, -2).trim() 
}
export class EventTypes extends React.Component {
  state = {
    value: ['eventId', 'assignmentId', 'quizId', 'discussionId', 'nonGradeId', 'appointmentId'],
    messages: []
  }

  token = null

  componentDidMount() {
    const {setEventType} = this.props
    const {value} = this.state
      const eventNames = getStringsFromId(value)
      setEventType(eventNames)
  }

  componentDidUpdate(prevProps, prevState) {
    const {setEventType, hasError, resolveError} = this.props
    const {value} = this.state
    if(prevState.value !== value) {
      const eventNames = getStringsFromId(value)
      setEventType(eventNames)
    }

    if(hasError && hasError !== prevProps.hasError) {
     this.setState({messages: !value.length ? [{ type: 'error', text: 'Select at least one option' }] : [] })
     resolveError()
   }
  }

  updateValue (value) {
    const index = this.state.value.indexOf(value)
    if (index === -1) {
      this.setState({
        value: [...this.state.value, value],
        messages: []
        })
    } else {
      this.setState((prevState) => ({
        value: [...prevState.value.slice(0, index), ...prevState.value.slice(index + 1)],
        messages: []
      }))
    }
  }

  render() {
    const {value, messages } = this.state

    return (
            <View padding="medium none" as="div" id="selectAllLabel">
              <FormFieldGroup description='Event types: *' messages={messages}>
                <Checkbox
                  aria-labelledby="selectAllLabel"
                  label={"Select none / Select all"}
                  value="all"
                  onChange={() => this.setState({
                    value: value.length === 6 ? [] : ['eventId', 'assignmentId', 'quizId', 'discussionId', 'nonGradeId', 'appointmentId'],
                    messages: []
                  })}
                  margin='small'
                  checked={value.length === 6}
                  indeterminate={value.length > 0 && value.length < 6}
                  defaultChecked
                />
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconCalendarMonthLine/> Events</View>}
                  value="eventId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('eventId') !== -1}
                />
                </View>
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconAssignmentLine/> Assignments </View>}
                  value="assignmentId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('assignmentId') !== -1}
                />
                </View>
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconQuizLine/> Quiz </View>}
                  value="quizId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('quizId') !== -1}
                />
                </View>
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconDiscussionLine/> Discussion </View>}
                  value="discussionId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('discussionId') !== -1}
                />
                </View>
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconDocumentLine/> Non-graded items with a to-do date </View>}
                  value="nonGradeId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('nonGradeId') !== -1}
                />
                </View>
                <View as='div' padding='0 0 0 medium'>
                <Checkbox
                  label={<View> <IconCalendarAddLine/> Appointment groups </View>}
                  value="appointmentId"
                  onChange={(event) => {
                    this.updateValue(event.target.value)
                  }}
                  checked={value.indexOf('appointmentId') !== -1}
                />
                </View>
              </FormFieldGroup>

            </View>
      )
  }
}

export default connect(null, {setEventType})(EventTypes)