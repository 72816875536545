/**
 * A custom error type for failures that are because the user doesn't have permission.
 * This is useful so that we can display an error about the operation.
 */
 class CustomError extends Error {
    constructor(errorType, message, status) {
      super(message);
      this.name = errorType;
      this.status = status;
    }
  }
  
  export default CustomError