export const ACTION_RESET_SUFFIX = ':reset';
export const ACTION_LOADED_SUFFIX = ':loaded';

// generic reducer for using the spinner loader while waiting for data from the reducer
const identityFunction = data => data;
export function createLoadingReducer(myaction, initialLoadingState = false, loadedCallback = identityFunction) {
    const loadingAction = myaction
    const loadedAction = myaction + ACTION_LOADED_SUFFIX
    const resetAction = myaction + ACTION_RESET_SUFFIX

    const initialState = {
        result: null,
        error: null,
        loading: initialLoadingState,
        requested: false
    };
    return function reducer(state = initialState, action) {
        switch(action.type) {
            case loadingAction: {
                // When we start loading we clear any old errors, but we keep any old result around. If the new load
                // takes a while then it'll be handy to have the old state in the store to display on the screen.
                return {
                    result: state.result,
                    error: null,
                    loading: true,
                    requested: true
                }
            }
            case loadedAction: {
                // Give the callback a chance to reformat the action, if we need to.
                loadedCallback(action);
                return {
                    result: action.result,
                    error: action.error,
                    loading: false,
                    requested: true
                }
            }
            case resetAction: {
                return initialState
            }
            default: {
                return state;
            }
        }
    };
}

// generic reducer for just setting the state once at a time e.g setting a boolean value
export function createFlagReducer(myAction) {

    return function reducer(state = false, action) {
        switch (action.type) {
            case myAction: {
                return action.result;
            }
            default: {
                return state;
            }
        }
    }
}