const PREFIX = 'event';
export const SET_DATE_TO = PREFIX + ':set:dateTo';
export const SET_DATE_FROM = PREFIX + ':set:dateFrom';
export const SET_TYPES = PREFIX + ':set:types';

export const CALENDAR_EVENTS = PREFIX + ':calendar';
export const ASSIGNMENTS = PREFIX + ':assignments';
export const DISCUSSIONS = PREFIX + ':discussions';
export const QUIZZES = PREFIX + ':quizzes';
export const TODOS = PREFIX + ':todos';
export const APP_GROUPS = PREFIX + ':appgroups';


export function getEvents(courseId, token, startDate, endDate, types) {
    return {
        type: CALENDAR_EVENTS,
        courseId,
        startDate,
        endDate,
        types,
        token
    }
}
export function getAssignments(courseId, token, startDate, endDate) {
    return {
        type: ASSIGNMENTS,
        courseId,
        startDate,
        endDate,
        token
    }
}
export function getDiscussions(courseId, token) {
    return {
        type: DISCUSSIONS,
        courseId,
        token
    }
}
export function getTodos(courseId, token, startDate, endDate) {
    return {
        type: TODOS,
        courseId,
        startDate,
        endDate,
        token
    }
}
export function getAppGroups(courseId, token, startDate, endDate, types) {
    return {
        type: APP_GROUPS,
        courseId,
        startDate,
        endDate,
        types,
        token
    }
}

export function setEventStartDate(value) {
    return {
        type: SET_DATE_FROM,
        result: value 
    }
}

export function setEventEndDate(value) {
    return {
        type: SET_DATE_TO,
        result: value 
    }
}

export function setEventType(value) {
    return {
        type: SET_TYPES,
        result: value 
    }
}