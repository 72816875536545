import { combineReducers } from 'redux';
import { PROMPT_LOGIN, SET_SETTINGS, SET_JWT } from '../actions/userAccess';
import { createFlagReducer } from '../utils/genericReducer';
import { dispatchEventLoad } from './app'

export const userAccessReducer = combineReducers({
	promptLogin: createFlagReducer(PROMPT_LOGIN),
	server: createFlagReducer(SET_SETTINGS),
	jwtToken: createFlagReducer(SET_JWT)
});

// This is just used so that when we get a token we re-try the loading of the data
export const userAccessMiddleware = [
	store => next => action => {
		const state = store.getState()
		switch(action.type) {
			case PROMPT_LOGIN: {
				const defaultNext = next(action)
				// If we've been asked to get a token and have successfully got one re-fire the loading of the data
				if(!action.result && state.app.showExport) {
					dispatchEventLoad(store)
				}
				return defaultNext
			}
			default:
				return next(action)
		}
	}
];
