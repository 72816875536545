import React from 'react'
import { View } from '@instructure/ui-view'
import {
  IconAssignmentLine,
  IconCalendarAddLine,
  IconCalendarMonthLine,
  IconCopyLine,
  IconDiscussionLine,
  IconDocumentLine,
  IconPdfLine,
  IconQuizLine
} from '@instructure/ui-icons'
import { setEventEndDate, setEventStartDate } from '../../actions/event'
import { Text } from '@instructure/ui-text'
import { connect } from 'react-redux'
import { Flex } from '@instructure/ui-flex'
import { Button } from '@instructure/ui-buttons'
import { Heading } from '@instructure/ui-heading'
import { parseDate } from '../../utils/commonFunctions'
import { List } from '@instructure/ui-list'
import {eventTypes as eventConst} from '../../utils/constants'
import {Tooltip} from '@instructure/ui-tooltip'
import { NoPrint } from './NoPrint'

export class PdfExport extends React.Component {

  state = {
    showCopyMessage: false,
    showNoPrint: false
  }

  formatDate = (date) => {
    const renderDate = parseDate(date, 'en-gb', this.props.timezone)
    return renderDate.format('D MMM YYYY ')
  }

  formatIsoDay = (date) => {
    const renderDate = parseDate(date, 'en-gb', this.props.timezone)
    return renderDate.format('YYYY-MM-DD')
  }

  formatTime = (date) => {
    const renderDate = parseDate(date, 'en-gb', this.props.timezone)
    return `${renderDate.format('LT')}`
  }

  formatDay = (date) => {
    const renderDate = parseDate(date, 'en-gb', this.props.timezone)
    return renderDate.format('ddd, D MMMM YYYY')
  }

  copyToClip = () => {

    document.getElementById('titleStyles').style.fontSize = 22
    let copyArea = document.getElementById('copyFile').innerHTML

    function listener(e) {
      e.clipboardData.setData('text/plain', copyArea)
      e.clipboardData.setData('text/html', copyArea)
      e.preventDefault()
    }

    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)

    setTimeout(() => {
      this.setState({ showCopyMessage: true })
    }, 500)
  }

  getTimeRange = (start_at, end_at = null, type) => {

    let time = 'none specified'
    if (start_at) {
      time = this.formatTime(start_at)

      if (end_at) {
        time += ` - ${this.formatTime(end_at)}`
      }
    }

    // add Due prefix to assignments, discussions and quizzes
    if ((type === eventConst.ASSIGNMENTS.toLocaleLowerCase()) || (type === eventConst.DISCUSSION.toLocaleLowerCase()) || (type === eventConst.QUIZ) || (type === undefined)) {
      time = `Due ${this.formatTime(start_at)}`
    }

    return time
  }

  handlePrint = () => {
    if (window.print === undefined) {
      this.setState({ showNoPrint: true })
    } else {
      window.print()
    }
  }

  renderIcon(currentEvent) {
    // default to appointment group
    let iconPlaceHolder = <IconCalendarAddLine title='Appointment' color='brand' />

    // check if this is an assignment, quiz or discussion
    if (currentEvent.assignment) {
      // check for quiz
      if (currentEvent.assignment.submission_types.includes('online_quiz')) {
        iconPlaceHolder = <IconQuizLine title='Quiz' color='brand' />
      } else {
        iconPlaceHolder = <IconAssignmentLine title='Assignment' color='brand' />
      }

      // check for events
    } else if (currentEvent.type === 'event') {
      iconPlaceHolder = <IconCalendarMonthLine title='Event' color='brand' />
    }

    if (currentEvent.todo_date !== undefined) {
      // check for discussions
      if (currentEvent.discussion_type) {
        iconPlaceHolder = <IconDiscussionLine title='Discussion' color='brand' />
      } else {
        iconPlaceHolder = <IconDocumentLine title='Todo' color='brand' />
      }
    }
    return iconPlaceHolder
  }

  filterEvents = (currentEvent) => {
    if (currentEvent.assignment) {
      // check for events
    } else if (currentEvent.type === 'event') {
      // return nothing for appointment groups that are not enrolled
      if (currentEvent['appointment_group_id']) {
        return false
      }
    }
    if (currentEvent.todo_date !== undefined) {
      if (!currentEvent.todo_date) {
        return false
      }
    }
    return true
  }

  render() {
    const {
      courseName,
      event,
      populatedEvents,
      handleClose,
      setEventEndDate,
      setEventStartDate,
      timezone
    } = this.props
    const { showCopyMessage, showNoPrint } = this.state

    let date = 'none specified'

    if (event.dateTo || event.dateFrom) {
      date = (event.dateFrom ? this.formatDate(event.dateFrom) : 'none specified') + ' to ' + (event.dateTo ? this.formatDate(event.dateTo) : 'none specified')
    }

    const groupedByDay = populatedEvents
      .map(unmappedEvent => {
        // map all events date to have a start date
        let mappedItem = unmappedEvent
        if (unmappedEvent.todo_date) {
          mappedItem['start_at'] = unmappedEvent.todo_date
        }
        return mappedItem
      })
      .filter(this.filterEvents)
      .reduce((byDay, event) => {
        const day = this.formatIsoDay(event.start_at);
        // eslint-disable-next-line no-param-reassign
        (byDay[day] = byDay[day] ? byDay[day] : []).push(event)
        return byDay
      }, {})

    let displayedEvents = <Text>There are no Events to display at this time</Text>

    if (Object.keys(groupedByDay).length > 0) {
      displayedEvents = Object.entries(groupedByDay)
        .sort(([aDay], [bDay]) => {
          return aDay.localeCompare(bDay)
        })
        .map(([day, events]) => {
          return <>
            <Heading level='h4' as='h3' margin='small 0 small' border='bottom'> {this.formatDay(day)} </Heading>
            <List isUnstyled itemSpacing="x-small">
              {events
                .sort((aEvent, bEvent) => aEvent.start_at.localeCompare(bEvent.start_at))
                .map(event => {
                  return (<List.Item key={event.id}>
                    <Flex alignItems='start'>
                      <Flex.Item size='30%' margin='none medium none none'>
                        <Text
                          size='medium'> {this.renderIcon(event)} {this.getTimeRange(event.start_at, event.end_at, event.type)} </Text>
                      </Flex.Item>
                      <Flex.Item>
                        <Text size='medium' color='brand'> {event.title}</Text>
                        {(event.location_address || event.location_name) &&
                        <Text size='small' as='div'> Event
                          Location: {event.location_name} {event.location_address}</Text>}
                      </Flex.Item>
                    </Flex>
                  </List.Item>)
                })
              }
            </List>
          </>
        })
    }

    return (
      <View padding='medium' as='div'>
        <span id='copyFile'>
            <Heading level='h1' margin='medium none'>Calendar for {courseName}</Heading>
            <View as='div' padding='small' background='primary' borderWidth='medium' borderColor='brand'
                  margin='small none medium none' borderRadius='small'>
              <List itemSpacing='small' id='titleStyles'>
                  <List.Item>
                    <Text weight='bold'>Date</Text>: {date}
                  </List.Item>
                  <List.Item>
                    <Text weight='bold'>Timezone</Text>: {timezone}
                  </List.Item>
                  <List.Item>
                    <Text weight='bold'>Event type</Text>: {event.eventTypes}
                    <br />
                  </List.Item>
              </List>
            </View>
            <Heading level='h3' as='h2'> Events </Heading>
            {displayedEvents}
            <br />
        </span>
        <NoPrint>
          <View borderWidth='small none none none' borderColor='primary' as='div'>
            <Flex justifyItems='end' wrap='wrap'>
              <Flex.Item margin='small xx-small'>
                <Button color='secondary' onClick={() => {
                  handleClose()
                  setEventEndDate(null)
                  setEventStartDate(null)
                }}>
                  Back
                </Button>
              </Flex.Item>
              <Flex.Item>
                <Tooltip renderTip={'Successfully copied and ready to paste in Word'} on='click'
                         isShowingContent={showCopyMessage}
                         onHideContent={() => this.setState({ showCopyMessage: false })}>
                  <Button color='secondary' margin='none small' onClick={() => this.copyToClip()}
                          renderIcon={<IconCopyLine />}>
                    Copy page to Word
                  </Button>
                </Tooltip>
              </Flex.Item>
              <Flex.Item>
                <Tooltip renderTip={'Printing isn\'t available on your browser'} on='click' isShowingContent={showNoPrint}
                         onHideContent={() => this.setState({ showNoPrint: false })}>
                  <Button color='primary' onClick={this.handlePrint} renderIcon={<IconPdfLine />}>
                    Print/Save to PDF
                  </Button>
                </Tooltip>
              </Flex.Item>
            </Flex>
          </View>
        </NoPrint>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    userAccess: { jwtToken },
    canvas: { canvasCourse },
    event: { eventTypes, events, assignments, discussions, todos, appGroups }
  } = state

  // concat arrays from all  different events
  let populatedEvents = []
  let dateFrom = new Date(eventTypes.dateFrom).getTime()
  let dateTo = new Date(eventTypes.dateTo).getTime()

  if (eventTypes.eventTypes.includes(eventConst.EVENTS)) {
    populatedEvents = populatedEvents.concat((events && events.result && events.result.data.filter(event => !event.hidden)) || [])
  }
  if (eventTypes.eventTypes.includes(eventConst.ASSIGNMENTS) || eventTypes.eventTypes.includes(eventConst.QUIZ)) {
    const quizAssignments = assignments && assignments.result && assignments.result.data.filter(event =>
          event.assignment.is_quiz_assignment || event.assignment.is_quiz_lti_assignment
    )
    const assignmentAssignments = assignments && assignments.result && assignments.result.data.filter(event =>
        !event.assignment.is_quiz_assignment && !event.assignment.is_quiz_lti_assignment
    )
    if(eventTypes.eventTypes.includes(eventConst.QUIZ)){
      populatedEvents = populatedEvents.concat(quizAssignments || [])
    }
    if(eventTypes.eventTypes.includes(eventConst.ASSIGNMENTS)){
      populatedEvents = populatedEvents.concat(assignmentAssignments || [])
    }
  }
  if (eventTypes.eventTypes.includes(eventConst.DISCUSSION)) {
    if (discussions && discussions.result && discussions.result.data) {
      let discussionsDate = discussions.result.data
      discussionsDate = discussionsDate.filter((item) => {
        let itemDate = new Date(item.todo_date).getTime()
        if (item.todo_date) {
          if (dateFrom <= itemDate) {
            if (!dateTo || (dateTo >= itemDate)) {
              return item
            }
          }
        }
        return null
      })
      populatedEvents = populatedEvents.concat(discussionsDate || [])
    }
  }
  if (eventTypes.eventTypes.includes(eventConst.TODOS)) {
    populatedEvents = populatedEvents.concat((todos && todos.result && todos.result.data) || [])
  }
  if (eventTypes.eventTypes.includes(eventConst.APP_GROUP)) {
    if (appGroups && appGroups.result && appGroups.result.data) {
      const appointmentSlots = appGroups.result.data.flatMap((item) => {
        if(isItemWithinDateRange(item.start_at, dateFrom, dateTo) && item.appointments?.length) return item.appointments
        return false
      }).filter((appointment) => {
        if(appointment.child_events.length){
          appointment.type = eventConst.APPOINTMENT
          return appointment
        }
        return false
      })
      populatedEvents = populatedEvents.concat(appointmentSlots || [])
    }
  }

  return {
    courseName: canvasCourse && canvasCourse.courseName,
    event: eventTypes,
    populatedEvents,
    token: jwtToken
  }
}

const isItemWithinDateRange = (itemDate, dateFrom, dateTo) => {
  if(!itemDate) return false
  const parsedDate = Date.parse(itemDate)
  return dateFrom <= parsedDate && dateTo >= parsedDate
}


export default connect(mapStateToProps, { setEventEndDate, setEventStartDate })(PdfExport)