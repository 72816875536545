const PREFIX = 'user';
export const PROMPT_LOGIN = PREFIX + ':prompt:login';
export const SET_SETTINGS = PREFIX + ':set:settings';
export const SET_JWT = PREFIX + ':set:jwt';


export const  promptUserLogin = (value = true) => {
    return{
        type: PROMPT_LOGIN,
        result: value 
    }
}

export const setSettings = (value = {}) => {
    return{
        type: SET_SETTINGS,
        result: value 
    }
}

export const setJwtToken = (value) => {
    return{
        type: SET_JWT,
        result: value 
    }
}
