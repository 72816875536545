import React from 'react'
import {connect} from 'react-redux'

export const NoPrint = ({children}) => {
  return (
    <div className="no-print" >
        {children}
      </div>
    )
}

export default connect(null, null)(NoPrint)